<template>
  <v-container style="height: 100%" class="pa-6 pt-2 bg-glass-effect">
    <v-row class="mb-10">
      <backArrow :route="'miniHomeCovid'"/>
    </v-row>
    <v-row class="mt-10">
      <v-col class="pt-0 mt-8" cols="9">
        <p style="font-size: 32px" class="main-title">Histórico das avaliações</p>
      </v-col>
      <v-col class="pt-0" cols="12">
        <v-card v-for="history in histories" :key="history.id" :color="isValid(history) && !history.release ? '#FFEFC5' : 'white'" class="list-card">
          <v-row class="ml-1" justify="center">
            <v-col class="px-5 pb-0" cols="2">
              <img src="/img/icons/menu-covid/calendar-icon.svg" style="width: 25px"/>
            </v-col>
            <v-col class="pl-0 pb-0" cols="10">
              <p style="font-size: 16px; padding-top: 1px;" class="generic-text">
                Avaliação {{ moment(history.generatedDate).format('DD/MM/YY') }} - {{ moment(history.generatedDate).format('HH:MM') }}
              </p>
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col class="px-16 pa-0 pr-0" cols="8">
              <p style="font-weight: bold;" class="mb-0 result-text">
                Resultado: <br/>
              </p>
              <p class="result-text">
                {{ history.release ? 'Liberado' : 'Possibilidade de COVID-19' }}
              </p>
            </v-col>
            <v-col cols="4">
              <v-chip
                v-if="isValid(history)"
                small
                style="color: #5A8BB0; padding-left: 10px; padding-right: 10px;"
                color="#CCE9F9"
              >
                Válido
              </v-chip>
              <v-chip
                v-else
                small
                style="color: #ACACB5; padding-left: 10px; padding-right: 10px;"
                color="#EFEFEF"
              >
                Inválido
              </v-chip>
            </v-col>
          </v-row>

          <v-row v-if="isValid(history) && !history.release">
            <v-col v-if="!history.medicalCertificate" class="mb-0" cols="12">
              <v-btn color="#CECCFF" class="attachment-btn" @click="onClickButtonAttachment(history)">
                <v-img class="mr-1" :src="'/img/icons/common/attachment.svg'"></v-img>
                <span style="color: #532E88;">Anexar Atestado</span>
              </v-btn>
            </v-col>
            <v-col v-else class="mb-0" cols="12">
              <v-btn @click="downloadAttach(history)" color="#CECCFF" class="attachment-btn">
                <v-img class="mr-1" :src="'/img/icons/common/check-square.svg'"></v-img>
                <span style="color: #532E88;">Atestado anexado</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="isValid(history)" justify="center" align="center">
            <v-col cols="12">
              <v-btn color="primary" class="qrcode-btn" @click="onClickButtonGenerateQrCode(history)">
                Gerar QR Code
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-intersect="infiniteScrolling"></v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import backArrow from '@/components/covid/back-arrow';
import { getScreeningHistory } from '../../common/euprotegido-service';
import { mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'screeningHistory',
  components: { backArrow },
  data() {
    return {
      page: 0,
      histories: [],
      isLastPage: false,
      moment
    }
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    isValid(screening){
      if(!screening.expireDate)
        return false;
      return moment().diff(screening.expireDate, 'minutes') < 0;
    },
    downloadAttach(history) {
      let a = document.createElement('a');
      a.href = 'data:application/octet-stream;base64,' + history.medicalCertificate;
      a.download = `${history.medicalCertificateFileName}${history.medicalCertificateContentType}`
      a.click();
    },
    getHistory() {
      this.loading(true);

      getScreeningHistory(this.page)
        .then( res => {
          this.histories = res.data.content;

          if(res.data.last) this.isLastPage = true;
        })
        .catch(() => {
          this.showmsg({
            text: 'Erro ao carregar o histórico de triagem!',
            type: 'error',
          })
        })
        .finally(() => this.loading(false));
    },
    infiniteScrolling(entries, observer, isIntersecting) {
      setTimeout(() => {
        if(!this.isLastPage) {
          this.page++;
          getScreeningHistory(this.page)
            .then( res => {
              if (!res.data.empty) {
                res.data.content.forEach(item => this.histories.push(item));
              }

              if(res.data.last) this.isLastPage = true;
            })
            .catch(() => {
              this.showmsg({
                text: 'Erro ao carregar o histórico de triagem!',
                type: 'error',
              })
            })
        }
      }, 500);
    },
    formateDateTime(dateTime){
      return moment(dateTime).format('DD/MM/YYYY hh:mm');
    },
    onClickButtonAttachment(screening){
      window.scrollTo(1, 1)
      this.$router.push({ name: 'orientacoesCovid', params: { screeningId : screening.id } })
    },
    onClickButtonGenerateQrCode(screening) {
      this.$router.push({
        name: 'qrCodeTriagem',
        params: {
          userName: this.$auth.user().name,
          generatedDateFormatted: this.formateDateTime(screening.generatedDate),
          exportData: screening.exportDataMedicalRelease || screening.exportData,
          fromHistory: true
        }
      })
    }
  },
  created() {
    this.getHistory();
  }
}
</script>

<style>
/*background effect blur*/
.bg-glass-effect{
  background: rgba(255,255,255,.8) !important;
  mix-blend-mode: normal !important;
  backdrop-filter: blur(100px) !important;
  height: 100vh;
}

.list-card {
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 12px !important;
  margin-bottom: 30px;
}

.qrcode-btn {
  border-radius: 18px;
  text-transform: initial !important;
  height: 36px !important;
  width: 75%;
  margin-left: 12%;
  margin-bottom: 10px;
}

.attachment-btn {
  border-radius: 12px;
  text-transform: initial !important;
  height: 36px !important;
  min-width: 45%;
  margin-left: 14%;
}
.result-text {
  font-family: Open Sans;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #868698;
}
</style>
